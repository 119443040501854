import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 xl:grid-cols-3 gap-4 items-start" }
const _hoisted_2 = { class: "flex flex-col items-center" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "text-center text-xs font-semibold w-full mt-2" }

import { ref } from "vue";

type Image = {
  id: string;
  name: string;
  url: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'Memes',
  setup(__props) {

const memes = ref<Image[]>([
  {
    id: "14",
    name: "10x Certified Blah Blah!",
    url: "/img/memes/10x-certified-medals.png",
  },
  {
    id: "13",
    name: "PHD Computer Scientist vs Self-taught Software Developer (Olympics 2024)",
    url: "/img/memes/phd-computer-scientist-vs-self-thought-software-dev-meme-olympics.png",
  },
  {
    id: "13",
    name: "You can't make everyone happy, you're not a jar of Nutella!",
    url: "/img/memes/happy-jar-nutella.png",
  },
  {
    id: "12",
    name: "Scrum Master meme",
    url: "/img/memes/scrum-master-meme.png",
  },
  {
    id: "11",
    name: "Cyber attack meme",
    url: "/img/memes/cyber-attack-meme.png",
  },
  {
    id: "10",
    name: "Nothing is more expensive than too cheap!",
    url: "/img/memes/quote-nothing-is-more-expensive.png",
  },
  {
    id: "9",
    name: "Serverless with 100x delicious dependencies",
    url: "/img/memes/serverless-microservices-architecture.png",
  },
  {
    id: "8",
    name: "",
    url: "/img/memes/quote-success-happiness.png",
  },
  {
    id: "7",
    name: "",
    url: "/img/memes/cat-leaving-work-friday-deployment-explode.jpeg",
  },
  {
    id: "6",
    name: "",
    url: "/img/memes/project-managment-manager-measure-lines-of-code.png",
  },
  {
    id: "5",
    name: "",
    url: "/img/memes/junior-dev-be-like.png",
  },
  {
    id: "4",
    name: "",
    url: "/img/memes/dont-know-what-it-does-code.png",
  },
  {
    id: "3",
    name: "",
    url: "/img/memes/dont-know-what-it-does-lambda.png",
  },
  {
    id: "2",
    name: "AWS exam or my project? 😅",
    url: "/img/memes/this-or-that-aws-exam-or-project.jpeg",
  },
  {
    id: "1",
    name: ".: PerSiaN caT :.",
    url: "/img/memes/persian-cat.jpeg",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memes.value, (meme) => {
      return (_openBlock(), _createElementBlock("div", {
        key: meme.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: meme.url,
            alt: meme.name,
            class: "w-full object-cover object-center rounded-t-lg"
          }, null, 8, _hoisted_3),
          _createElementVNode("caption", _hoisted_4, _toDisplayString(meme.name), 1)
        ])
      ]))
    }), 128))
  ]))
}
}

})